import app from './base'
import popup from './popup'
import $t from './translation'
import {parseCommaFormatted, formatAmount, getActionUrl, countPercentageValue, boolval} from './helper'
import {APP_NAME} from "./constants";
import configuration from "./configuration";

const basket = {
    form: {
        element: function() {
            return jQuery('#basket_update_form');
        },
        button: function() {
            return basket.form.element().find('#do_checkout');
        },
        renderError: function(error) {
            basket.form.showErrors(`<div id="error" class="system-message system-message-error"><div class="left"><div class="error"></div></div><div id="error-list">${error}</div></div>`);
        },
        showErrors: function(errors) {
            $(errors).insertAfter('.basket-shipping-box');
        },
        clearErrors: function() {
            basket.form.element().find('#error').remove();
        },
        update: function() {
            jQuery("input[name=operation]").val("update");
            return jQuery.ajax({
                type: "POST",
                url: basket.form.element().attr('action'),
                data: basket.form.element().serialize() + "&layout=ajax",
            });
        }
    },
    item: {
        price: {
            netto: {
                element: function (productId) {
                    return jQuery("#product_" + productId + "_price")
                },
                raw: function (productId) {
                    return basket.item.price.netto.element(productId).attr('data-price')
                },
                get: function (productId) {
                    return parseCommaFormatted(basket.item.price.netto.raw(productId))
                }
            },
            brutto: {
                element: function (productId) {
                    return jQuery("#product_" + productId + "_price")
                },
                raw: function (productId) {
                    return basket.item.price.brutto.element(productId).html()
                },
                get: function (productId) {
                    return parseCommaFormatted(basket.item.price.brutto.raw(productId))
                }
            },
            additional_warranty: {
                element: function (productId) {
                    return jQuery("#product_" + productId + "_additional_price")
                },
                selected: function (productId) {
                    return basket.item.price.additional_warranty.element(productId).find('input:checked').length > 0;
                },
                raw: function (productId) {
                    return basket.item.price.additional_warranty.element(productId).attr('data-price-additional-warranty')
                },
                get: function (productId) {
                    return parseCommaFormatted(basket.item.price.additional_warranty.raw(productId))
                },
                set: function (productId, value) {
                    basket.item.price.additional_warranty.element(productId).find('.price-value-amount').html(formatAmount(value));
                }
            }
        },
        quantity: {
            element: function (productId) {
                return jQuery("input[name='product[" + productId + "][amount]']")
            },
            raw: function (productId) {
                return basket.item.quantity.element(productId).val()
            },
            set: function (productId, quantity) {
                basket.item.quantity.element(productId).val(quantity);
                basket.item.quantity.save(productId);

                app.event.trigger("basket.item.quantity.set", this, {
                    'product-id': productId,
                    value: quantity
                })
            },
            get: function (productId) {
                return parseCommaFormatted(basket.item.quantity.raw(productId))
            },
            increase: function (productId) {
                let b = basket.item.quantity.get(productId);
                basket.item.quantity.set(productId, b + 1)
            },
            decrease: function (productId) {
                let b = basket.item.quantity.get(productId) - 1;
                b < 0 && (b = 0), basket.item.quantity.set(productId, b)
            },
            update: function (productId) {
                let b = basket.item.quantity.get(productId);
                b.length > 0 && basket.item.quantity.set(productId, b)
            },
            save: function(productId) {
                basket.form.update().done(function(response, textStatus) {
                    basket.form.clearErrors();
                    if(response.length > 0 && $(response).find('#error-list').length > 0)
                    {
                        basket.form.showErrors(response);
                    } else {
                        if( basket.item.quantity.get(productId) === 0 ) {
                            let el = basket.item.quantity.element(productId).parents("tr:first");
                            el.prev().remove();
                            el.next().remove();
                            el.remove();
                        }

                        app.event.trigger('basket.changed');
                    }
                });
            }
        },
        subtotal: {
            get: function (productId) {
                return basket.item.price.netto.get(productId) * basket.item.quantity.get(productId)
            }
        },
        total: {
            element: function (productId) {
                return jQuery("#product_" + productId + "_sum")
            },
            raw: function (productId) {
                return basket.item.total.element(productId).html()
            },
            get: function (productId) {
                return parseCommaFormatted(basket.item.total.raw(productId))
            },
            set: function (productId, total) {
                basket.item.total.element(productId).html(formatAmount(total))
                app.event.trigger("basket.item.total.set", this, {
                    id: productId,
                    value: total
                })
            },
            update: function (productId) {
                basket.item.total.set(productId, basket.item.price.brutto.get(productId) * basket.item.quantity.get(productId))
            }
        }
    },
    price: {
        element: function() {
            return jQuery("#basket-total-sum");
        },
        raw: function() {
            return basket.price.element().html();
        },
        get: function() {
            if (basket.price.element().attr('data-price')) {
                return parseCommaFormatted(basket.price.element().attr('data-price'));
            }

            return parseCommaFormatted(basket.price.raw());
        },
        set: function(price) {
            basket.price.element().attr('data-price', price);
            basket.price.element().html(formatAmount(price));
            app.event.trigger("basket.price.set");
        },
        update: function() {
            let b = 0;
            for (let a = jQuery("input[name='products[]']"), c = 0; c < a.length; c++) {
                let d = jQuery(a[c]).val();
                b += basket.item.subtotal.get(d)
            }
            basket.price.set(b);
        }
    },
    shipping: {
        element: function () {
            return jQuery("#order-total-shipping")
        },
        methodElement: function() {
            return basket.form.element().find('select[name="order[delivery][method]"]');
        },
        countryElement: function() {
            return jQuery('select[name="order[shipping][country]"]');
        },
        postalCodeElement: function() {
            return basket.form.element().find('input[name="order[shipping][zip]"]');
        },
        parcelTerminal: function() {
            return basket.form.element().find('select[name="order[shipping][details][parcel_terminal]"]');
        },
        parcelTerminalControl: function() {
            return basket.shipping.parcelTerminal().closest('.control');
        },
        billingAsShippingElement: function() {
            return basket.form.element().find('input[name="order[billing][same_as_shipping]"]');
        },
        parcelTerminalReset: function() {
            basket.shipping.parcelTerminal().data('country', '');
            basket.shipping.parcelTerminal().data('method', '');
            basket.shipping.parcelTerminal().empty();
            basket.shipping.parcelTerminal().append($('<option>', {
                value: "",
                text: $t('select.delivery_parcel_terminal', false, false),
            }));
        },
        addressReset: function() {
            jQuery('.delivery-info-box').hide();
        },
        parcelTerminalAddress: function() {
            return jQuery('#parcel-terminal-address');
        },
        billingAddress: function() {
            return jQuery('#billing-address');
        },
        shippingTimeElement: function() {
            return jQuery('#shipping-time');
        },
        raw: function () {
            return basket.shipping.element().attr("data-price")
        },
        set: function (a) {
            a = formatAmount(a),
                basket.shipping.element().attr("data-price", a),
                basket.shipping.element().html(a),
                app.event.trigger("basket.shipping.set")
        },
        setFree: function () {
            basket.shipping.set(formatAmount(0));
            basket.shipping.element().html($t("delivery.free"))
        },
        get: function () {
            return parseCommaFormatted(basket.shipping.raw())
        },
        button: function() {
            return basket.form.element().find('.basket-shipping-box').find('.button');
        },
        loading: function(isLoading) {
            if (isLoading) {
                basket.shipping.button().addClass("is-loading");
            } else {
                basket.shipping.button().removeClass("is-loading");
            }
        },
        reset: function () {
            basket.shipping.addressReset();
            basket.shipping.billingAsShippingElement().prop('checked', true);
            basket.shipping.billingAddress().hide();
        },
        update: function(submitChanges) {
            basket.shipping.addressReset();
            const addressElement = basket.shipping.methodElement().find(':selected').data('address');

            if (addressElement !== undefined) {
                const $addressElement = $(addressElement);
                if ($addressElement.is(":hidden")) {
                    $addressElement.show();
                }
            } else {
                basket.form.clearErrors();
            }

            const shippingMethod = basket.shipping.methodElement().val();
            const shippingCounty = basket.shipping.countryElement().val();

            let parcelTerminal = "";

            if (basket.shipping.methodElement().find(':selected').data('parcel-terminals') === true) {
                if (shippingCounty === "") {
                    basket.shipping.parcelTerminalReset();
                    basket.shipping.parcelTerminalAddress().find('.form-box').html($t('err_delivery.parcel_terminal'));
                    return;
                }

                const parcelTerminalElement = basket.shipping.parcelTerminal();

                if (parcelTerminalElement.data('country') !== shippingCounty || parcelTerminalElement.data('method') !== shippingMethod) {
                    basket.shipping.updateParcelTerminals(shippingCounty, shippingMethod);
                    basket.shipping.parcelTerminalAddress().find('.form-box').html($t('err_delivery.parcel_terminal'));
                    return;
                }

                if (parcelTerminalElement.val() === "") {
                    parcelTerminalElement.parent().addClass("is-danger");
                    basket.shipping.parcelTerminalAddress().find('.form-box').html($t('err_delivery.parcel_terminal'));
                    return;
                } else {
                    parcelTerminal = parcelTerminalElement.val();
                    parcelTerminalElement.parent().removeClass("is-danger");
                    basket.shipping.parcelTerminalAddress().find('.form-box').html(parcelTerminalElement.find(':selected').data('address'));
                }
            }

            let pickupLocation = "";

            if (basket.shipping.methodElement().find(':selected').data('pickup-locations') === true) {
                if (shippingCounty === "") {
                    basket.shipping.parcelTerminalReset();
                    basket.shipping.parcelTerminalAddress().find('.form-box').html($t('err_delivery.pickup_location'));
                    return;
                }

                const pickupLocationElement = basket.shipping.packeta.pickupLocationElement();

                if (pickupLocationElement.val() === "") {
                    pickupLocationElement.parent().addClass("is-danger");
                    basket.shipping.packeta.pickupLocationAddress().find('.form-box').html($t('err_delivery.pickup_location'));
                    return;
                } else {
                    pickupLocation = pickupLocationElement.val();
                    pickupLocationElement.parent().removeClass("is-danger");
                    basket.shipping.packeta.pickupLocationAddress().find('.form-box').html(pickupLocationElement.find(':selected').data('address'));
                }
            }

            if (submitChanges) {
                basket.form.element().find('input[name=operation]').val('update');

                // post form
                jQuery.ajax({
                    type: "POST",
                    url: basket.form.element().attr('action'),
                    data: basket.form.element().serialize() + "&layout=ajax",
                    success: function (response, textStatus) {
                        basket.form.clearErrors();

                        // we dont want to prevent user from seeing shipping price if error occurs but the data is enough
                        // to calculate shipping price
                        // if(response.length > 0 && $(response).find('#error-list').length > 0) {
                        //     basket.form.showErrors(response);
                        // }

                        basket.shipping.update(false);
                    },
                });
                return;
            }

            const basketId = basket.form.element().find('input[name=id_basket]').val();

            if (shippingMethod === "") {
                basket.shipping.methodElement().parent().addClass("is-danger");
            } else {
                basket.shipping.methodElement().parent().removeClass("is-danger");
            }

            if (shippingCounty === "") {
                basket.shipping.countryElement().parent().addClass("is-danger");
            } else {
                basket.shipping.countryElement().parent().removeClass("is-danger");
            }

            if (shippingMethod === "" || shippingCounty === "") {
                return;
            }

            basket.shipping.loading(true);

            jQuery.ajax({
                type: "POST",
                url: getActionUrl("basket", "getShippingCost", {
                    store: configuration.get('store', 'com'),
                    lang: configuration.get('lang', 'en'),
                }),
                data: {
                    'layout': 'ajax'
                    ,'id_basket': basketId
                    ,'order[delivery][method]': shippingMethod
                    ,'order[shipping][country]': shippingCounty
                    ,'order[shipping][zip]': basket.shipping.postalCodeElement().val()
                    ,'order[shipping][details][parcel_terminal]': parcelTerminal
                    ,'order[shipping][details][pickup_location]': pickupLocation
                },
                success: function (response, textStatus) {
                    basket.form.clearErrors();

                    if(response.length > 0 && $(response).find('#error-list').length > 0)
                    {
                        basket.form.showErrors(response);
                        basket.shipping.set(formatAmount(0));
                        basket.shipping.shippingTimeElement().html('');
                    } else {
                        if (response.is_free) {
                            basket.shipping.setFree();
                        } else {
                            basket.shipping.set(response.price);
                        }

                        basket.shipping.shippingTimeElement().html(response.time || '');

                        if (response.tax_percentage >= 0) {
                            basket.tax.percentage.set(response.tax_percentage);
                            basket.tax.total.update();
                        }

                        if (response.can_request_discount) {
                            basket.info.discount.show();
                        } else {
                            basket.info.discount.hide();
                        }

                        if (response.error) {
                            basket.form.renderError(response.error);
                        }
                    }
                },
                complete: function () {
                    basket.shipping.loading(false);
                }
            });
        },
        updateParcelTerminals: function (shippingCounty, shippingMethod) {
            basket.shipping.parcelTerminalReset();
            basket.shipping.parcelTerminal().data('country', shippingCounty);
            basket.shipping.parcelTerminal().data('method', shippingMethod);

            basket.shipping.loading(true);

            jQuery.ajax({
                type: "POST",
                url: getActionUrl("shipping", "getParcelTerminals", {
                    store: configuration.get('store', 'com'),
                    lang: configuration.get('lang', 'en'),
                }),
                data: {
                    'layout': 'ajax'
                    ,'order[delivery][method]': shippingMethod
                    ,'order[shipping][country]': shippingCounty
                },
                success: function (response, textStatus) {
                    basket.form.clearErrors();

                    if(response.length > 0 && $(response).find('#error-list').length > 0) {
                        basket.form.showErrors(response);
                    } else {
                        let groups = [];

                        for(let i in response) {
                            const item = response[i];

                            if (groups[item.city] === undefined) {
                                groups[item.city] = $('<optgroup label="' + item.city + '">');
                            }

                            groups[item.city].append($('<option>', {
                                value: item.key,
                                text : item.name,
                                'data-address': item.name + ', ' + item.address,
                            }));
                        }

                        const cities = Object.keys(groups).sort();
                        const parcelTerminal = basket.shipping.parcelTerminal();

                        for(let i in cities) {
                            const group = groups[cities[i]];
                            parcelTerminal.append(group);
                        }
                    }
                },
                complete: function () {
                    basket.shipping.loading(false);
                }
            });
        },
        updatePhoneAreaCode: function () {
            const countryPhoneCode = basket.shipping.countryElement().find(':selected').data('phoneCode');

            if (countryPhoneCode !== "") {
                jQuery('.phone-country-code').html(countryPhoneCode);
            }
        },
        packeta: {
            pickupLocationElement: function() {
                return basket.form.element().find('input[name="order[shipping][details][pickup_location]"]');
            },
            pickupLocationControl: function() {
                return basket.shipping.packeta.pickupLocationElement().closest('.control');
            },
            pickupLocationValue: function() {
                return jQuery('#pickup-location-value');
            },
            pickupLocationAddress: function() {
                return jQuery('#pickup-location-address');
            },
            pickupLocationSelectButton: function() {
                return jQuery('#pickup-location-selector');
            },
            open: function (apiKey) {
                var head = document.getElementsByTagName('head')[0];

                if (head.querySelector('#packeta-library') !== null) {
                    return basket.shipping.packeta.show(apiKey);
                }

                var script = document.createElement('script');
                script.setAttribute('id', 'packeta-library');
                script.setAttribute('type', 'text/javascript');
                script.setAttribute('src', ('https:' == document.location.protocol ? 'https://' : 'http://') + 'widget.packeta.com/v6/www/js/library.js');
                script.addEventListener('load', function () {
                    basket.shipping.packeta.show(apiKey);
                });
                head.insertBefore(script, head.firstChild);
            },
            show: function (apiKey) {
                basket.shipping.packeta.pickupLocationSelectButton().addClass('is-loading');
                Packeta.Widget.pick(apiKey, function (point) {
                    app.event.trigger("basket.shipping.packeta.selected", this, {
                        point: point
                    });
                }, {
                    language: document.documentElement.lang,
                    country: basket.shipping.countryElement().find(':selected').data('isoCode').toLowerCase(),
                })
            },
            selected: function (point) {
                basket.shipping.packeta.pickupLocationSelectButton().removeClass('is-loading');

                if (point !== null) {
                    // if (point.carrierId != null && point.carrierPickupPointId != null) {
                    //     basket.shipping.packeta.pickupLocationElement().val(point.carrierId + '.' + point.carrierPickupPointId);
                    // } else {
                    //     basket.shipping.packeta.pickupLocationElement().val(point.id);
                    // }

                    basket.shipping.packeta.pickupLocationElement().val(Buffer.from(JSON.stringify(point)).toString("base64"));

                    let addressLines = [
                        point.place,
                        point.name
                    ];

                    if (point.directions) {
                        addressLines.push(point.directions);
                    }

                    if (point.openingHours.tableLong) {
                        addressLines.push(point.openingHours.tableLong);
                    }

                    basket.shipping.packeta.pickupLocationValue().html(point.name);
                    basket.shipping.packeta.pickupLocationAddress().find('.form-box').html(
                        addressLines.join('<br>')
                    );
                    basket.shipping.packeta.pickupLocationSelectButton().addClass('is-hidden');
                } else {
                    basket.shipping.packeta.pickupLocationElement().val('');

                    basket.shipping.packeta.pickupLocationValue().html('');
                    basket.shipping.packeta.pickupLocationAddress().find('.form-box').html($t('err_delivery.pickup_location'));
                    basket.shipping.packeta.pickupLocationSelectButton().removeClass('is-hidden');
                }

                app.event.trigger('basket.shipping.update');
            },
            reset: function() {
                basket.shipping.packeta.pickupLocationElement().val('');
                basket.shipping.packeta.pickupLocationValue().html('');
                basket.shipping.packeta.pickupLocationAddress().find('.form-box').html($t('err_delivery.pickup_location'));
            },
        }
    },
    billing: {
        element: function () {
            return jQuery("#billing-address")
        },
        show: function () {
            basket.billing.element().show();
        },
        hide: function () {
            basket.billing.element().hide();
        },
    },
    tax: {
        percentage: {
            element: function () {
                return jQuery("#tax-price")
            },
            raw: function () {
                return basket.tax.percentage.element().attr("data-percentage")
            },
            get: function () {
                return parseCommaFormatted(basket.tax.percentage.raw())
            },
            set: function (percentage) {
                return basket.tax.percentage.element().attr("data-percentage", percentage)
            }
        },
        total: {
            element: function () {
                return jQuery("#tax-price")
            },
            raw: function () {
                return basket.tax.total.element().html()
            },
            get: function () {
                return parseCommaFormatted(basket.tax.total.raw())
            },
            set: function (tax) {
                basket.tax.total.element().html(formatAmount(tax));
                app.event.trigger("basket.tax.set");
            },
            update: function () {
                basket.tax.total.set(((basket.price.get() + basket.shipping.get() + basket.additional_warranty.price.get() - basket.discount.total.get()) * basket.tax.percentage.get())/100);
            }
        }
    },
    subtotal: {
        get: function() {
            return basket.price.get() + (((basket.price.get() - basket.discount.total.get()) * basket.tax.percentage.get())/100);
        }
    },
    total: {
        element: function () {
            return jQuery("#order-final-sum")
        },
        raw: function () {
            return basket.total.element().html()
        },
        get: function () {
            return parseCommaFormatted(basket.total.raw())
        },
        set: function (total) {
            basket.total.element().html(formatAmount(total));
            app.event.trigger("basket.total.set");
        },
        update: function () {
            basket.total.set(basket.price.get() + basket.shipping.get() + basket.additional_warranty.price.get() + basket.tax.total.get() - basket.discount.total.get())
        }
    },
    discount: {
        percentage: {
            element: function () {
                return jQuery("#order-discount-price")
            },
            raw: function () {
                return basket.discount.percentage.element().attr("data-percentage")
            },
            get: function () {
                return parseCommaFormatted(basket.discount.percentage.raw())
            }
        },
        total: {
            element: function () {
                return jQuery("#order-discount-price")
            },
            raw: function () {
                return basket.discount.total.element().html()
            },
            get: function () {
                return parseCommaFormatted(basket.discount.total.raw())
            },
            set: function (total) {
                basket.discount.total.element().html(formatAmount(total));
                app.event.trigger("basket.discount.set")
            }
        },
        update: function () {
            basket.discount.total.set(countPercentageValue(basket.price.get(), basket.discount.percentage.get()))
        }
    },
    info: {
        discount: {
            element: function () {
                return jQuery("#left_to_discount")
            },
            button: function() {
                return jQuery("#request-discount .button")
            },
            get: function () {
                return basket.info.discount.element().attr("data-value")
            },
            set: function (discount) {
                discount < 0 && (discount = 0), basket.info.discount.element().html(formatAmount(discount))
            },
            update: function () {
                var total = basket.total.get(), limit = basket.info.discount.get();
                basket.info.discount.set(limit - total);
                if ((limit - total) > 0) {
                    basket.info.discount.button().attr("disabled", "disabled");
                } else {
                    basket.info.discount.button().removeAttr("disabled");
                }
            },
            show: function() {
                basket.info.discount.element().show();
                basket.info.discount.button().show();
            },
            hide: function() {
                basket.info.discount.element().hide();
                basket.info.discount.button().hide();
            }
        },
        shipping: {
            element: function () {
                return jQuery("#left_to_free_shipping")
            },
            get: function () {
                return basket.info.shipping.element().attr("data-value")
            },
            set: function (shipping) {
                shipping < 0 && (shipping = 0), basket.info.shipping.element().html(formatAmount(shipping))
            },
            update: function () {
                basket.info.shipping.set(basket.info.shipping.get() - basket.subtotal.get())
            }
        },
        min_amount: {
            error: {
                container: function() {
                    return $('.content-box')
                },
                show: function(minAmount, totalAmount) {
                    if (basket.info.min_amount.error.container().children('#error').length > 0) {
                        return;
                    }

                    basket.info.min_amount.error.container().prepend('<div id="error" class="system-message system-message-error"><div class="left"><div class="error"></div></div><div id="error-list">' + $t('err_sauna.product_min_amount', {min: minAmount, total: totalAmount}) + '</div></div>');
                    jQuery(".item-quantity.item-has-min").closest('.columns').addClass('has-error');
                },
                hide: function() {
                    basket.info.min_amount.error.container().children('#error').remove();
                    jQuery(".item-quantity.item-has-min").closest('.columns').removeClass('has-error');
                },
            },
            update: function () {
                let total = 0, min = parseInt(basket.form.element().attr("data-min-amount"));
                jQuery(".item-quantity.item-has-min").each(function () {
                    total += parseInt(jQuery(this).val())
                });

                if(basket.form.button().attr('type') === "submit") {
                    if(min > total) {
                        basket.info.min_amount.error.show(min, total);
                        basket.form.button().attr('disabled', 'disabled');
                        basket.info.discount.button().hide();
                    } else {
                        basket.info.min_amount.error.hide();
                        basket.form.button().removeAttr('disabled');
                        basket.info.discount.button().show();
                    }
                }
            }
        },
        update: function () {
            basket.info.discount.update();
            basket.info.shipping.update();
            basket.info.min_amount.update();
        }
    },
    additional_warranty: {
        selected: function() {
            const checkboxes = document.querySelectorAll('.additional-warranty-box input');
            let selected = false;

            for (let i in checkboxes) {
                if (checkboxes[i].checked) {
                    selected = true
                }
            }

            return selected;
        },
        estimate: {
            element: function () {
                return $('#order-additional-warranty-estimate')
            },
            get: function () {
                return basket.additional_warranty.estimate.element().html()
            },
            set: function (value) {
                // estimate should be with tax
                let estimate = parseFloat(value);
                estimate += estimate * basket.tax.percentage.get() / 100;
                basket.additional_warranty.estimate.element().html(formatAmount(estimate))
            },
        },
        price: {
            container: function () {
                return $('#additional-warranty-info-box')
            },
            element: function () {
                return $('#additional-warranty-price')
            },
            raw: function () {
                if (!basket.additional_warranty.selected()) {
                    return null;
                }

                return basket.additional_warranty.price.element().html()
            },
            get: function () {
                return parseCommaFormatted(basket.additional_warranty.price.raw());
            },
            set: function (value) {
                basket.additional_warranty.price.element().html(formatAmount(value))
            },
        },
        update: function () {
            let total = 0;

            for (let a = jQuery("input[name='products[]']"), c = 0; c < a.length; c++) {
                const e = jQuery(a[c]);
                const productId = e.val();

                const warrantyPrice = basket.item.price.additional_warranty.get(productId);

                if (warrantyPrice === 0) {
                    continue;
                }

                const quantity = basket.item.quantity.get(productId);

                basket.item.price.additional_warranty.set(productId, (warrantyPrice * quantity));

                if (!basket.item.price.additional_warranty.selected(productId)) {
                    e.closest('.product-line').removeClass('additional-warranty');
                    continue;
                }

                e.closest('.product-line').addClass('additional-warranty');

                total += warrantyPrice * quantity;
            }

            if (!basket.additional_warranty.selected()) {
                return;
            }

            basket.additional_warranty.estimate.set(total);
            basket.additional_warranty.price.set(total);
        },
        show: function () {
            basket.additional_warranty.update()
            basket.additional_warranty.price.container().show();
            basket.tax.total.update();
            basket.total.update();
        },
        hide: function () {
            jQuery('.product-line').removeClass('additional-warranty');

            basket.additional_warranty.price.container().hide();
            basket.additional_warranty.estimate.set(0);
            basket.additional_warranty.price.set(0);
            basket.tax.total.update();
            basket.total.update();
        },
        toggle: function(checkbox) {
            if (basket.additional_warranty.selected()) {
                basket.additional_warranty.show();
            } else {
                basket.additional_warranty.hide();
            }
        }
    }
};

app.event.bind('basket.add', function(params) {
    params.event.stopPropagation();
    params.event.preventDefault();
    const href = params.href || jQuery(this).attr('href');
    popup.url(href, '', function () {
        app.event.trigger("basket.preview.update");
    });
});

app.event.bind('basket.preview.update', function(params) {
    jQuery.ajax({
        type: "GET",
        url: getActionUrl("basket", "get", {
            store: configuration.get('store', 'com'),
            lang: configuration.get('lang', 'en'),
        }),
        data: {
            'layout': 'ajax'
        },
        dataType: 'json',
        success: function (response, textStatus) {
            try {
                const data = JSON.parse(response);
                jQuery(".cart-summary-box .basketAmount").html(data.amount);
                jQuery(".cart-summary-box .basketSumm").html(data.summ);
            } catch (e) {

            }
        }
    });
});

app.event.bind('basket.item.quantity.decrease', function(params) {
    const productId = params['product-id'];
    basket.item.quantity.decrease(productId);
});

app.event.bind('basket.item.quantity.increase', function(params) {
    const productId = params['product-id'];
    basket.item.quantity.increase(productId);
});

app.event.bind('basket.item.quantity.change', function(params) {
    const productId = params['product-id'];
    const quantity = jQuery(this).val();

    if (quantity === "") {
        return;
    }

    basket.item.quantity.set(productId, jQuery(this).val());
});

app.event.bind('basket.item.quantity.set', function(params) {
    const productId = params['product-id'];
    basket.additional_warranty.update();
    basket.item.total.update(productId);
    app.event.trigger("basket.preview.update");
});

app.event.bind('basket.item.total.set', function() {
    basket.price.update();
});

app.event.bind('basket.price.set', function() {
    basket.discount.update();
});

app.event.bind('basket.total.set', function() {
    basket.info.update();
});

app.event.bind("basket.shipping.set", function () {
    basket.discount.update()
});

app.event.bind('basket.shipping.country.update', function() {
    basket.shipping.updatePhoneAreaCode();
    basket.shipping.update(true);

    const isEU = basket.shipping.countryElement().find(':selected').data('isEu');

    if (boolval(isEU)) {
        jQuery('.out_of_eu_shipping_info_box').hide();
    } else {
        jQuery('.out_of_eu_shipping_info_box').show();
    }
});

app.event.bind('discount.shipping.country.update', function() {
    basket.shipping.updatePhoneAreaCode();
});

app.event.bind('basket.shipping.method.update', function() {
    basket.shipping.reset();

    const shippingMethod = basket.shipping.methodElement().val();

    if (shippingMethod === "") {
        basket.shipping.parcelTerminalControl().hide();
        basket.shipping.packeta.pickupLocationControl().hide();
    } else if (shippingMethod === "none") {
        basket.shipping.billingAsShippingElement().prop('checked', false);
        basket.shipping.billingAddress().show();
        basket.shipping.parcelTerminalControl().hide();
        basket.shipping.packeta.pickupLocationControl().hide();
    } else if (basket.shipping.methodElement().find(':selected').data('parcel-terminals') === true) {
        basket.shipping.billingAsShippingElement().prop('checked', false);
        basket.shipping.billingAddress().show();
        basket.shipping.parcelTerminalControl().show();
        basket.shipping.packeta.pickupLocationControl().hide();
    } else if (basket.shipping.methodElement().find(':selected').data('pickup-locations') === true) {
        basket.shipping.billingAsShippingElement().prop('checked', false);
        basket.shipping.billingAddress().show();
        basket.shipping.parcelTerminalControl().hide();
        basket.shipping.packeta.pickupLocationControl().show();
    } else {
        basket.shipping.billingAddress().hide();
        basket.shipping.parcelTerminalControl().hide();
        basket.shipping.packeta.pickupLocationControl().hide();
    }

    basket.shipping.update(true);
});

app.event.bind('basket.shipping.update', function() {
    basket.shipping.update(true);
});

app.event.bind("basket.discount.set", function () {
    basket.tax.total.update();
});

app.event.bind("basket.tax.set", function () {
    basket.total.update()
});

app.event.bind('basket.changed', function() {
    basket.price.update();
    basket.shipping.update(false);
});

app.event.bind('basket.request', function(params) {
    const $button = jQuery(this);
    $button.addClass('is-loading');

    basket.form.update().done(function(response, textStatus) {
        basket.form.clearErrors();
        $button.removeClass('is-loading');

        if(response.length > 0 && $(response).find('#error-list').length > 0)
        {
            basket.form.showErrors(response);
        } else {
            popup.html(params.popup);
        }
    });
});

app.event.bind('basket.toggle.billing', function () {
    if (!this.checked) {
        basket.billing.show();
    } else {
        basket.billing.hide();
    }
});

app.event.bind('basket.shipping.packeta.select', function (params) {
    params.event.preventDefault();
    basket.shipping.packeta.open(params.key);
});

app.event.bind('basket.shipping.packeta.selected', function (params) {
    basket.shipping.packeta.selected(params.point);
});

app.event.bind('order.payment.method.change', function (data) {
    let buttonText = this.getAttribute('data-button');
    if (buttonText && buttonText.length > 0) {
        document.getElementsByClassName('is-success')[0].innerHTML = buttonText;
    }
});

app.event.bind('basket.toggle.additional_warranty', function () {
    basket.additional_warranty.toggle(this);
});
