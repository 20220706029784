import { APP_NAME } from './constants';
import app from './base'
import qtip from "qtip2/dist/jquery.qtip";
import daterangepicker from "daterangepicker/daterangepicker";
import popup from "./popup";
import storage from "./storage";
import {boolval, getActionUrl} from './helper'
import enquire from "enquire.js/dist/enquire"
import configuration from "./configuration";

var googleTls;

function addToCompare(product_id) {
    var current_compare = storage.cookie.getObjectValue("compare_products");

    if(typeof(current_compare) != 'undefined' && current_compare != '') {

        current_compare = current_compare.split(',');
        if(current_compare.indexOf(product_id) >= 0) return;
    }

    var compare_count = storage.cookie.getObjectValue("compare_count");

    if(typeof(compare_count) == 'undefined' || isNaN(compare_count) || compare_count < 1) compare_count = 0;

    compare_count++;

    storage.cookie.setObjectValue("compare_count", compare_count);

    if(typeof(current_compare) != 'undefined' && current_compare.length > 0 && current_compare != '') current_compare = current_compare + "," + product_id;
    else current_compare = product_id;

    storage.cookie.setObjectValue("compare_products", current_compare);
}

function removeFromCompare(product_id) {
    var current_compare = storage.cookie.getObjectValue("compare_products");

    if(typeof(current_compare) != 'undefined') {
        current_compare = current_compare.split(',');

        if(current_compare.indexOf(product_id) >= 0) {
            current_compare.splice((current_compare.indexOf(product_id)), 1);
            storage.cookie.setObjectValue("compare_products", current_compare.join(','));

            var compare_count = current_compare.length;
            storage.cookie.setObjectValue("compare_count", compare_count);

            app.event.trigger('popup.form', jQuery(".button.compare:first"), {'class': 'compare-popup'});
        }
    }
}

app.initialize = function () {

};

app.event.bind('document.ready', function() {
    // File input selected file name display
    const fileFields = jQuery(".file-input");

    if (fileFields) {
        fileFields.each(function() {
            const $this = jQuery(this);
            $this.on('change', () => {
                if (this.files && this.files.length > 0) {
                    let names = [];
                    for(let i = 0, l = this.files.length; i < l; i++) {
                        names.push(this.files[i].name);
                    }
                    $this.parent("label").find(".file-name").html(names.join(", "));
                }
            });
        });
    }

    // Add title bubble-tips
    $('a[title],div[title],input[alt]').qtip({
        position: {
            my: 'bottom center',
            at: 'top center'
        }
        , style: {
            classes: 'ui-tooltip-black'
        }
    });

    enquire.register("screen and (min-width:769px)", function() {
        const welcomePopUp = jQuery('.welcome-pop-up');
        if( welcomePopUp.length > 0 ) {
            const welcomeShown = storage.cookie.get('welcome-pop-up');
            if(!welcomeShown) {
                popup.html(welcomePopUp, 'welcome-pop-up-wrap');
                storage.cookie.set('welcome-pop-up', true);
            }
        }
    }, true);

    const gdprPopUp = jQuery('.gdpr-pop-up');
    if( gdprPopUp.length > 0 ) {
        const gdprShown = storage.cookie.get('gdpr-pop-up');
        if(!gdprShown) {
            gdprPopUp.show();
            gdprPopUp.delegate('.button-close', 'click', function() {
                gdprPopUp.remove();
                storage.cookie.set('gdpr-pop-up', true);
            });
        }
    }

    const project_box = jQuery('.project-box');
    if(project_box.length > 0) {
        project_box.click(function() {
            window.location = jQuery(this).find('a').attr('href');
        });
    }

    var googleTranslateLoaded = false;
    var googleTranslateLoad = function () {
        if (googleTranslateLoaded) {
            googleTls.showBanner();
            return;
        }

        var head = document.getElementsByTagName('head')[0];
        var googleTranslate = document.createElement('script');
        googleTranslate.setAttribute('type', 'text/javascript');
        googleTranslate.setAttribute('src', ('https:' == document.location.protocol ? 'https://' : 'http://') + 'translate.google.com/translate_a/element.js?cb=' + APP_NAME + '.googleTranslateInit');
        head.insertBefore(googleTranslate, head.firstChild);
        googleTranslateLoaded = true;
    }

    const googleTranslateClicked = storage.cookie.get('google-translate');
    if(googleTranslateClicked) {
        googleTranslateLoad();
    }

    jQuery('.google-translate').click(googleTranslateLoad);
});

app.googleTranslateInit = function() {
    googleTls = new google.translate.TranslateElement({
        pageLanguage: document.documentElement.lang,
        layout: google.translate.TranslateElement.InlineLayout.SIMPLE
    });

    const googleTranslateClicked = storage.cookie.get('google-translate');
    if(!googleTranslateClicked) {
        googleTls.showBanner();
    }

    storage.cookie.set('google-translate', true);
};

app.event.bind('welcome.popup.language', function(params) {
    var $this = $(this);
    jQuery('.language-active').removeClass('language-active');
    $this.addClass('language-active');

    jQuery.ajax({
        type: "POST",
        url: $this.attr('href'),
        data: "layout=ajax",
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            popup.close();
        },
        success: function (response, textStatus) {
            jQuery('.welcome-pop-up-content').html(response);
        }
    });
});

app.event.bind('product.gallery.show', function(params) {
    jQuery('.gallery-slider').find('a:first').trigger('click');
});

app.event.bind('product.compare.add', function(params) {
    params.event.stopPropagation();
    params.event.preventDefault();
    addToCompare(params.id);
    jQuery(this).hide();
    jQuery(this).next().show();
    if(storage.cookie.getObjectValue('compare_count') > 1) jQuery('.compare').removeClass('grey').addClass('green');
});

app.event.bind('product.compare.remove', function(params) {
    params.event.stopPropagation();
    params.event.preventDefault();
    removeFromCompare(params.id);
    jQuery(this).hide();
    jQuery(this).prev().show();
    if(storage.cookie.getObjectValue('compare_count') < 2) jQuery('.compare').addClass('grey').removeClass('green');
});

app.event.bind('product.compare.view.init', function(params) {
    $(this).find('a[title],div[title],input[alt]').qtip({
        position: {
            my: 'bottom center',
            at: 'top center'
        }
        , style: {
            classes: 'ui-tooltip-black'
        }
    });
});

function hidePropsEqual(){
    jQuery('.hideEqual').each(function () {
        if(this.style.display == 'none'){
            jQuery(this).css( {display: ''});
        }else{
            jQuery(this).css( {display: 'none'});
        }
    });
}

app.event.bind('product.compare.view.diff', function(params) {
    jQuery(this).addClass('grey').removeClass('green').prop("disabled", true);
    jQuery(this).next().addClass('green').removeClass('grey').prop("disabled", false);
    hidePropsEqual();
});

app.event.bind('product.compare.view.all', function(params) {
    jQuery(this).addClass('grey').removeClass('green').prop("disabled", true);
    jQuery(this).prev().addClass('green').removeClass('grey').prop("disabled", false);
    hidePropsEqual();
});

app.event.bind('product.variation.select', function(params) {
    // clear variant selection
    jQuery('input[name^=properties]').each(function() {
        let has_new = false;
        let old_val = $(this).val();
        if(old_val.length == 0) return;

        old_val = old_val.split(',');

        for(let i = 0; i<old_val.length; i++) {
            if(params.value.indexOf(old_val[i]) != -1) has_new = true;
        }

        if(!has_new) {
            $(this).val('');
        }
    });

    // set selection
    jQuery('#properties_' + params.group + '_' + params.property).val(params.value);

    // post
    jQuery.ajax({
        type: "POST",
        url: getActionUrl("shop", "sameProducts", {
            store: configuration.get('store', 'com'),
            lang: configuration.get('lang', 'en'),
            id: params.id,
            menu_id: params.menu_id
        }),
        data: jQuery('#product-variants-form').serialize() + "&layout=ajax",
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            //console.error('variants select error', textStatus);
        },
        success: function (response, textStatus) {
            jQuery(params.container).html(response);
        }
    });
});

app.event.bind('tabs.init', function() {
    const tabActiveClass = 'is-active';
    const $container = jQuery(this);
    $container.find('.tabs ul li a').on('click', function() {
        const $link = jQuery(this);
        $container.find('.tabs .' + tabActiveClass).removeClass(tabActiveClass);
        $link.parent().addClass(tabActiveClass);

        $container.find('.tab-content.' + tabActiveClass).removeClass(tabActiveClass);
        const $content = jQuery($link.attr('href'));
        $content.addClass('is-active');
    });
});

app.event.bind('datepicker.init', function() {
    jQuery(this).daterangepicker({
        locale: {
            format: 'DD.MM.YYYY',
            firstDay: 1,
            applyLabel: "Apply",
            cancelLabel: "Cancel",
            fromLabel: "From",
            toLabel: "To",
            customRangeLabel: "Custom",
            buttonClasses: "button",
            applyButtonClasses: "is-primary",
            cancelButtonClasses: "is-link",
        }
    }).on('apply.daterangepicker', function(ev, picker) {
        const $field = jQuery(this);
        const startField = $field.data('start-field');
        const endField = $field.data('end-field');

        if (startField) {
            jQuery(startField).val(picker.startDate.format('DD.MM.YYYY'));
        }

        if (endField) {
            jQuery(endField).val(picker.endDate.format('DD.MM.YYYY'));
        }
    });
});

app.event.bind("currency.switch", function (params) {
    jQuery.ajax({
        type: "POST",
        url: getActionUrl("sauna", "switchCurrency", {
            store: configuration.get('store', 'com'),
            lang: configuration.get('lang', 'en'),
            currency: params.currency
        }),
        success: function (response, textStatus) {
            window.location.reload();
        }
    });
});

app.event.bind("lazyload", function (params) {
    const url = params["url"] || '';

    if (url.length === 0) {
        return;
    }

    const $this = $(this);

    jQuery.ajax({
        type: "GET",
        url: url,
        success: function (response) {
            $this.html(response);
        }
    });
});

app.event.bind('region.set', function(params) {
    const $form = jQuery(this);
    const popup = $form.closest('[data-pop-up]').first().get(0);
    const $option = $form.find(":selected");
    const url = $option.attr('data-url');
    const current = $option.attr('data-current');

    if (boolval(current)) {
        popup.dispatchEvent(new Event('pop-up-close'));
        return;
    }

    window.location = url;
});

export default app;
