import app from './base';
import validator from './validator'
import $t from './translation';

function toggleCountryList(formType, options) {
    const countryField = $('select[name="i_country"]');
    countryField.empty();
    options.each(function (c) {
        if ($(this).attr('data-groups').indexOf(formType) >= 0) {
            countryField.append($(this).clone())
        }
    })
}

app.event.bind("user.registration", function () {
    var $form = jQuery(this);
    $form.bind("form.submit.done", function() {
        grecaptcha.reset();
    });
    validator.init($form);

    const formTypeField = $('input[name="region"]');
    const countries = $('select[name="i_country"]').clone();

    formTypeField.change(function () {
        const value = $(this).val();

        if (value.length < 1) {
            return;
        }

        toggleCountryList(value, countries.find('option'));
    });

    if (formTypeField.val().length > 0) {
        toggleCountryList(formTypeField.val(), countries.find('option'));
    }
});

app.event.bind("user.registration.type.switch", function () {
    if (this.value == "corporate") {
        jQuery("#i_business_name").attr("data-val", "1").data("val-rule", "required");
        jQuery("#i_kmkr").attr("data-val", "1");
        jQuery("#i_business_registration_number").attr("data-val", "1");
        //jQuery("input[name=type]").val("corporate");
        jQuery("#corporate_box").show();
        jQuery('.phone-country-code').html('');
    } else {
        jQuery("#i_business_name").removeAttr("data-val").removeData("valRule").removeClass("validation_error");
        jQuery("#i_kmkr").removeAttr("data-val").removeData("valRule").removeClass("validation_error");
        jQuery("#i_business_registration_number").removeAttr("data-val").removeClass("validation_error");
        //jQuery("input[name=type]").val("profile");
        jQuery("#corporate_box").hide();
        jQuery('.phone-country-code').html('');
    }
})

app.event.bind("user.registration.country.update", function () {
    const countryPhoneCode = jQuery("#i_country").find(':selected').data('phoneCode');

    if (countryPhoneCode !== "") {
        jQuery('.phone-country-code').html(countryPhoneCode);
    }
})
